<template>
  <div>
    <defheader />
    <router-view />
    <deffooter />
  </div>
</template>
<script>
import defheader from "@/components/public/header"
import deffooter from "@/components/public/footer"
export default {
  components: {
    defheader,
    deffooter
  },
  data () {
    return {};
  },
  created () { },
  mounted () { },
  methods: {},
  computed: {} //计算属性
};
</script>
<style lang='scss' scoped>
</style>