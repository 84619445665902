/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-28 14:34:03
 * @Module: 公共头部
 */
 <template>
  <header :class="hasScrollbar ? '' : 'hasScrollbar'">
    <!-- {{hasScrollbar}} -->
    <div class="header-content">
      <div class="header-content-l">
        <img src="@/static/img/laboratory/blacklogo.png" alt="logo" class="logo hover" @click="to_path('/homepage')" />
        <div v-for="(item, index) in navList" :key="index" class="nav-box hover" :class="{ 'nav-ac': item.name == now_name }" @click="() => to_path(item.path)">
          {{ item.title }}
          <div class="solid"></div>
        </div>

        <el-dropdown @command="path => to_path(path)" placement="bottom-start">
          <div class="nav-box hover" style="color: #333;">关于我们</div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in aboutList" :key="index" :command="item.path">{{ item.title }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="header-content-r">
        <!-- <img src="@/static/homepage/laboratory_btn.png"
             alt="实验室"
             width="60"
             class="hover"
             @click="to_path('/laboratory')" /> -->
        <el-dropdown :hide-on-click="false" @command="liveCLick">
          <img src="@/static/homepage/laboratory_btn.png" alt="实验室" width="60" class="hover" />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="https://zhq.mcn-open.com/">真好签</el-dropdown-item>
            <el-dropdown-item command="https://zhq-profile.mcn-open.com">内部管理系统</el-dropdown-item>
            <!-- <el-dropdown-item command="https://shangzhibo.tv/watch/10172742">二号会议室</el-dropdown-item>
            <el-dropdown-item command="https://shangzhibo.tv/watch/10172746">三号会议室</el-dropdown-item>
            <el-dropdown-item command="https://shangzhibo.tv/watch/10172747">四号会议室</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <a href="https://zhq.mcn-open.com/" target="_blank" /> -->
        <el-dropdown v-if="userInfo" @command="handleCommand">
          <div class="header-content-r-userInfo hover">
            <el-avatar :size="20" :src="userInfo.avatar" @dblclick.native="openAdministrator" style="margin-right:5px;"></el-avatar>
            {{ userInfo.nick_name }}
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in menuList" :key="item.key" :command="item.key" style="position: relative;" :divided="item.key == 'exit'">
              {{ item.title }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <div v-else @click="to_login" class="hover">登录 | 注册</div>
      </div>
    </div>
  </header>
</template>
 <script>
import userInfo from "@/mixins/userinfo";
export default {
  components: {},
  data () {
    return {
      now_path: "",
      now_name: "",
      menuList: [
        {
          title: "我的主页",
          key: "index"
        },
        {
          title: "编辑资料",
          key: "edit"
        },
        // {
        //   title: '私信',
        //   key: 'message'
        // },
        {
          title: '消息中心',
          key: 'notice'
        },
        {
          title: "星探推荐",
          key: "xtTj"
        },
        {
          title: "退出登录",
          key: "exit"
        }
      ],
      aboutList: [
        // {
        //   title: "身份查询",
        //   path: "/certification",
        //   name: "certification"
        // },
        {
          title: "功能介绍",
          path: "/about",
          name: "about"
        },
        {
          title: "广告推广",
          path: "/promotion",
          name: "promotion"
        },
        {
          title: "常设机构",
          path: "/certification-organ",
          name: "certification-organ"
        }
      ],
      navList: [
        {
          title: "首页",
          path: "/homepage",
          name: "homepage"
        },
        // {
        //   title: "我是星探",
        //   path: "/star",
        //   name: "star"
        // },
        {
          title: "格调学院",
          path: "/college",
          name: "college"
        },
        {
          title: "APP下载",
          path: "/download",
          name: "download"
        }
      ],
      hasScrollbar: true
    };
  },
  created () { },
  mounted () {
  },
  mixins: [userInfo],
  methods: {
    searchBtn () {
      this.$store.commit("layouts/changeSearchDialogVisible", true);
    },
    to_path (path) {
      if (path !== this.now_path) {
        if (path == "/laboratory") {
          const { href } = this.$router.resolve({
            path
          });
          window.open(href, "_blank"); //打开新的窗口
        } else {
          this.$router.push(path);
        }
      }
    },
    to_login () {
      this.$store.commit("layouts/changeLoginVisible", true);
    },
    handleCommand (key) {
      const { uuid } = this.userInfo;
      if (key == "exit") {
        this.$store.commit("user/clearUserInfo");
        this.$store.commit("user/openAdministrator", "close");
        window.clearVuexAlong();
      } else if (key == "index") {
        this.$store.dispatch("homepage/toPersonal", { uuid });
      } else if (key == "edit") {
        this.$store.dispatch("homepage/toProfileEdit", { uuid });
      } else if (key == "xtTj") {
        console.log(this.userInfo)
        if (this.userInfo.can_accept_anchor) {
          this.$router.push({
            path: "/star/anchor",
            query: {
              tab: "2"
            }
          });
        } else {
          this.$message('成为王牌运营可开通星探推荐，详情请咨询官方客服')
        }
      } else if (key == "message") {
        this.$router.push("/homepage/chat");
      } else if (key == "notice") {
        this.$router.push("/homepage/notice");
      }
    },
    liveCLick (href) {
      window.open(href, "_blank");
      // if (this.userInfo) {
      //   const { parSid } = this.userInfo;
      //   window.open(href + "?parSid=" + parSid, "_blank");
      // } else {
      //   window.open(href, "_blank");
      // }
    },
    openAdministrator () {
      this.$prompt('请输入口令', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if (value === "zhongnengmoli") {
          this.$message({
            type: 'success',
            message: '开启管理员权限'
          });
          this.$store.commit("user/openAdministrator")
        } else {
          this.$message({
            type: 'error',
            message: '口令输入错误'
          });
        }

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        this.now_name = val.path.split("/")[1];
        this.now_path = val.path;
        this.$nextTick(() => {
          this.hasScrollbar =
            document.documentElement.offsetHeight >
            document.documentElement.clientHeight;
        });
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
  computed: {
    // hasScrollbar () {
    //   console.log(document.documentElement.offsetHeight > document.documentElement.clientHeight)
    //   return document.documentElement.offsetHeight > document.documentElement.clientHeight;
    // }
  }
};
</script>
 <style lang='scss' scoped>
.hasScrollbar {
  padding-right: 8px;
}
header {
  height: 60px;
  width: 100%;
  position: fixed;
  z-index: 1002;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
  background: #fff;
  margin-bottom: 10px;
}
.nav-box {
  padding: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #333;
  font-size: 16px;
  margin-right: 24px;
}
.nav-ac {
  color: #333;
  position: relative;
  height: 100%;
  font-weight: 600;

  .solid {
    width: 30px;
    height: 3px;
    background: #df2a29;
    border-radius: 2px;
    position: absolute;
    bottom: 8px;
    left: 0px;
    right: 0px;
    margin: auto;
  }
}
.header-content {
  width: 1240px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-l {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #999;
    height: 100%;
    .logo {
      width: 156px;
      margin-right: 26px;
    }
  }
  &-r {
    display: flex;
    color: #333333;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    div {
      margin-left: 40px;
    }
    div:first-child {
      margin: 0px;
    }
    &-userInfo {
      display: flex;
      align-items: center;
      height: 100%;
    }
    a {
      height: 30px;
      width: 80px;
      background-image: url(~@/static/img/zhenhaoqian.png);
      background-size: 100% auto;
      cursor: pointer;
    }
  }
}
</style>